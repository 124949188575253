<template>
  <el-card>
    <div slot="header" class="card-header">
      <h3 style="margin: 0">201 DASHBOARD</h3>
    </div>
    <el-dialog title="Add Item" :visible.sync="addDialog">
      <el-form ref="addForm" :model="addForm" label-width="120px">
        <el-form-item label="Name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogModule = false">Cancel</el-button>
        <el-button type="primary" @click="addName">Confirm</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Edit Name" :visible.sync="dialog">
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="Item Name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">Cancel</el-button>
        <el-button type="primary" @click="updateName">Confirm</el-button>
      </span>
    </el-dialog>
    <el-cascader :options="modules" v-model="value" @change="getNames"></el-cascader>
    <el-button v-if="value.length" type="primary" @click="addDialog = true">Add Name</el-button>
    <el-divider></el-divider>
    <el-collapse accordion class="hidden-md-and-up">
      <el-collapse-item v-for="(name, index) in data" :key="index" :name="name.id">
        <template slot="title">
          <p style="width: 100%">{{name.backend_global_4_name}}</p>
        </template>
        <div class="collapse-buttons">
          <el-tooltip class="item" effect="dark" content="Edit Name" placement="top-start">
            <el-button type="success" icon="el-icon-edit" size="mini" @click="editName(name)"></el-button>
          </el-tooltip>
        </div>
      </el-collapse-item>
    </el-collapse>
    <el-table :data="data" border class="hidden-sm-and-down">
      <el-table-column label="ID" prop="id" width="100px"></el-table-column>
      <el-table-column label="MODULE NAME" prop="backend_global_1_name"></el-table-column>
      <el-table-column label="HEADER NAME" prop="backend_global_2_name"></el-table-column>
      <el-table-column label="ITEM NAME" prop="backend_global_3_name"></el-table-column>
      <el-table-column label="NAME" prop="backend_global_4_name"></el-table-column>
      <el-table-column label="ACTIONS" fixed="right" width="170">
        <template slot-scope="props">
          <el-button-group>
            <el-button type="success" icon="el-icon-edit" size="mini" @click="editName(props.row)"></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: 'HR201FileSystem',
  data () {
    return {
      drawer: false,
      search: null,
      dialog: false,
      addDialog: false,
      value: [],
      data: [],
      modules: [],
      headers: [],
      items: [],
      form: {
        id: null,
        name: null
      },
      addForm: {
        bg3_id: null,
        name: null
      }
    }
  },
  mounted () {
    this.getModules()
  },
  methods: {
    getModules () {
      this.$http
        .get('hr.global-maintenance')
        .then(res => {
          this.modules = res.data.body
        })
    },
    getHeaders (value) {
      this.$http
        .get(`backend.global2-list/${value}`)
        .then(res => {
          this.headers = res.data.body
        })
    },
    getItems (value) {
      this.$http
        .get(`backend.global3-list/${value}`)
        .then(res => {
          this.items = res.data.body
        })
    },
    getNames () {
      this.addForm.bg3_id = this.value[this.value.length - 1]
      this.$http
        .get(`backend.global4-item-list/${this.value[this.value.length - 1]}`)
        .then(res => {
          this.data = res.data.body
        })
    },
    addName () {
      this.addDialog = false
      this.$http
        .post('backend.global4', this.addForm)
        .then(() => {
          this.getNames()
          this.$message({
            message: 'New Name Added',
            type: 'success'
          })
        })
        .catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    },
    editName (row) {
      this.dialog = true
      this.form.id = row.id
      this.form.name = row.backend_global_4_name
    },
    updateName () {
      this.dialog = false
      this.$http
        .post('backend.global4-update', this.form)
        .then(() => {
          if (this.value) {
            this.getNames()
          }
          this.$message({
            message: 'Item Updated',
            type: 'success'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-cascader {
    margin-right: 1rem;
    width: 700px;
  }
</style>
